import { Box, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"

export interface OverviewProps {
  width: number
}

export default function Overview({ width }: OverviewProps) {
  const messsages = [
    "Sans changer de WMS",
    "Sans changer vos méthodes de préparation",
    "Sans changer votre matériel",
    "Sans investissement coûteux",
    "Sans engagement",
  ]

  const [index, setIndex] = useState(0)

  useEffect(() => {
    const t = setTimeout(() => {
      setIndex((index + 1) % messsages.length)
    }, 2000)
    return () => clearInterval(t)
  }, [index, messsages.length])

  return (
    <>
      <Stack
        direction={width < 900 ? "column" : "row"}
        justifyContent="center"
        alignItems="center"
        spacing={5}
        sx={{ m: 4, mt: 6 }}
      >
        <Stack alignItems="start" maxWidth="500px">
          <Typography variant="h1" fontWeight="bold" width="100%" fontSize={45}>
            Réduisez les distances parcourues dans vos entrepôts
          </Typography>
        </Stack>
        <Box
          component="img"
          src="/laptop.png"
          width="100%"
          maxWidth="400px"
          sx={{ borderRadius: 30 }}
        />
      </Stack>
      <Typography
        variant="h6"
        sx={{
          m: 2,
          mb: 6,
          background: "#1a437c",
          color: "white",
          pr: 2,
          pl: 2,
          borderRadius: 5,
        }}
      >
        {messsages[index]}
      </Typography>
    </>
  )
}
