import { Box, Stack, Typography } from "@mui/material"

export interface DetailsProps {
  width: number
}

export default function Details({ width }: DetailsProps) {
  return (
    <Stack
      direction={width < 900 ? "column" : "row"}
      justifyContent="center"
      alignItems="center"
      spacing={5}
      sx={{ background: "#35845d", p: 0, pt: 10, pb: 10 }}
      width="100%"
    >
      <Box
        component="img"
        src="/tech.jpg"
        maxWidth="420px"
        sx={{ borderRadius: 2 }}
      />

      <Stack maxWidth={420} textAlign="start" spacing={2} sx={{ pl: 1, pr: 2 }}>
        <Typography variant="body1" color={"white"} fontSize="large">
          L’organisation d’un entrepôt est un système complexe, difficile à
          optimiser en utilisant des outils artisanaux basés sur la méthode ABC.
        </Typography>
        <Typography variant="body1" color={"white"} fontSize="large">
          WM-up vous propose de rationaliser la gestion de vos entrepôts grâce à
          un logiciel spécifique exploitant des algorithmes issus de la
          recherche scientifique.
        </Typography>
        <Typography variant="body1" color={"white"} fontSize="large">
          WM-up vous propose des actions correctives dont l’impact est finement
          quantifié.
        </Typography>
      </Stack>
    </Stack>
  )
}
